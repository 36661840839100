<template>
  <v-container fluid class="fill-height ma-0 pa-0">
    <banner-component/>
    <search-panel-component/>
    <!-- <about-as-component /> -->
    <!-- <features-component /> -->
    <the-best-tijuana-component/>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <medical-tour-component/>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <certified-doctors-component/>
      </v-col>
    </v-row>
    <specialties-component/>
    <cultural-voyage-component/>
    <!-- <last-news-component/> -->
    <!-- <comments-component /> -->
    <!-- Footer of application -->
    <footer-component/>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          width="400px"

      >
        <v-card style="background-color: rgba(255,255,255,0)">
          <v-card-text justify="center" align="center">
            <img src="@/assets/images/icons/escudo_coepris.f8331f99.png" class="img-modal" @click="goDoctors">
            <span class="superior-derecha" @click="closeModal">X</span>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import BannerComponent from "@/components/home/BannerComponent.vue";
// import AboutAsComponent from "@/components/home/AboutAsComponent.vue";
// import FeaturesComponent from "@/components/home/FeaturesComponent.vue";
import TheBestTijuanaComponent from "@/components/home/TheBestTijuanaComponent.vue";
import MedicalTourComponent from "@/components/home/MedicalTourComponent.vue";
import CertifiedDoctorsComponent from "@/components/home/CertifiedDoctorsComponent.vue";
import SpecialtiesComponent from "@/components/home/SpecialtiesComponent.vue";
import CulturalVoyageComponent from "@/components/home/CulturalVoyageComponent.vue";
// import LastNewsComponent from '@/components/home/LastNewsComponent.vue';
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
// import CommentsComponent from "@/components/comments/CommentsComponent";
import SearchPanelComponent from "@/components/home/SearchPanelComponent";

export default {
  name: "HomePageComponent",
  components: {
    SearchPanelComponent,
    BannerComponent,
    // AboutAsComponent,
    // FeaturesComponent,
    TheBestTijuanaComponent,
    MedicalTourComponent,
    CertifiedDoctorsComponent,
    SpecialtiesComponent,
    CulturalVoyageComponent,
    // LastNewsComponent,
    // CommentsComponent,
    FooterComponent,
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    closeModal() {
      this.dialog = false
    },
    goDoctors() {
      this.$router.push({
        name: "Doctors"
      });
    }
  },
  mounted() {
    this.$store.commit("HideSearchPanel");
    this.dialog = true
  },
};
</script>

<style scoped>
.superior-derecha {
  cursor: pointer;
  padding: 20px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4b4949;
  border-radius: 50px;
  color: white;
  position: absolute;
  top: 10px;
  right: 40px;
  width: 20px;
  height: 20px;
}
.img-modal{
  width: 300px;
  height: 300px;
  cursor: pointer;
}
</style>

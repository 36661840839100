<template>
  <v-container fluid class="d-flex fill-height text-center mt-0 mb-0 mx-0 pt-10 pb-15">
    <v-row dense no-gutters justify="center" align="start" class="text-center">
      <v-col cols="12">
        <span class="blue--text text-md-h4 change-font-family"> Here you will find the best of Baja California </span>
      </v-col>
      <v-col cols="12">
        <h1 class="text-md-h4 text-uppercase change-font-family">Working together</h1>
      </v-col>
      <v-col cols="12">
        <p class="text-md-h5 grey--text change-font-family">
          Experienced and Certified Doctors
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row align="start" justify="space-around" class="text-left">
          <v-col v-for="(item, index) in cardItems" :key="index" cols="12" xs="12" sm="6" md="3">
            <a v-bind:href="item.linkextra" style="text-decoration: none !important">
              <v-card style="border-radius: 30px !important" elevation="5" rounded="10" height="520">
                <v-img :src="item.url" height="250"></v-img>
                <v-card-subtitle class="d-flex align-center">
                  <v-icon color="brown" style="font-size: 40px !important ; margin-right: 2px">{{ item.icon }}</v-icon>
                  <span class="grey--text text--darken-2" style="font-size: 24px !important">{{ item.title }}</span>
                </v-card-subtitle>
                <v-card-text>
                  <v-row align="center" class="mx-0">
                    <v-col cols="12">
                      <div style="font-size: 18px !important" class="font-weight-light grey--text text--darken-1">
                        {{ item.detail }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions style="margin-left: 11px">
                  <v-btn transparent text color="blue" plain :to="item.linkTo" style="margin-bottom: 0"><span>see
                      more</span>
                    <v-icon small>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TheBestTijuanaComponent",
  data: () => ({
    cardItems: [
      {
        icon: "mdi-doctor",
        url: require("@/assets/images/doctor-final.jpg"),
        title: "Doctors",
        detail:
          "Get in touch with the best doctors, all the information you need in one place.",
        linkTo: "/doctors",
        linkextra: "/doctors",
        flex: 3,
      },
      {
        icon: "mdi-hospital-building",
        url: require("@/assets/images/hospital-clinica.jpg"),
        title: "Hospitals and Clinics",
        detail:
          "At BHC we provide data and information to help you make the best hospital or clinic decision.",
        linkTo: "/hospitals-clinics",
        linkextra: "/hospitals-clinics",
        flex: 3,
      },
      {
        icon: "mdi-silverware-fork-knife",
        url: require("@/assets/images/work-together-restaurants.jpg"),
        title: "Restaurants and Entertainment",
        detail:
          "Enjoy Mexico’s most revolutionary cuisine, from the classic tacos to Michelin-starred fine dining.",
        linkTo: "/restaurants-leisures",
        linkextra: "/restaurants-leisures",
        flex: 3,
      },
      {
        icon: "mdi-bed",
        url: require("@/assets/images/work-together-hotels.jpg"),
        title: "Hotels",
        detail:
          "Hotels have reinvented their services offering wide options to receive medical patients.",
        linkTo: "/hotels",
        linkextra: "/hotels",
        flex: 3,
      },
    ],
  }),
};
</script>

<style scoped>
/* .backcolor {
  background-color: rgba(229, 223, 240, 2);
} */

.v-btn {
  text-transform: none !important;
}

.change-font-family {
  font-family: 'Montserrat', sans-serif !important;
}
</style>
